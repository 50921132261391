<template>
  <div
    class="d-flex level-container justify-content-center">
    <i
      v-if="cardLevels.length > 2"
      class="pointer icon-angle-left pull-left pr-1 d-flex align-items-center"
      @click="previousLevel()"
    />
    <b-row
      class="d-flex justify-content-center w-100"
    >
      <b-col
        v-for="(card, i) of cardLevels"
        v-if="(parseInt(i) < index) && (index - parseInt(i) < 3)"
        :key="'level-key' + i"
        cols="6"
      >
        <card-level
          :card="card"
          :class="selectedCardClass(card) ? 'selected-card' : relatedLevel ? 'pointer' : ''"
          :selected="selectedCardClass(card)"
          @on:card:selected="$emit('on:card:selected', $event)"
        />
      </b-col>
    </b-row>
    <i
      v-if="cardLevels.length > 2"
      class="pointer icon-angle-right pull-right pl-1 d-flex align-items-center"
      @click="nextLevel()"
    />
  </div>
</template>
<script>

export default {
  components: {
    CardLevel: () => import('@views/client/details/activities/CardLevel')
  },
  data: () => ({
    index: 2,
    next: true,
    previous: true,
  }),
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    relatedLevel: {
      type: Object,
      default: null
    },
    cardLevels: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectedCardClass(card) {
      if (this.relatedLevel) {
        return (card.userRelatedActivityId === this.relatedLevel.id);
      }

      return false;
    },
    cssVars(colors) {
      return {
        '--firstColor': colors[0],
        '--secondColor': colors[1]
      }
    },
    nextLevel() {
      if (this.index < this.cardLevels.length) {
        this.index++;
      }
    },
    previousLevel() {
      if (this.index > 2) {
        this.index--;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.level-container {
  .icon-angle-left {
    color: #707070;
    font-size: medium;

    &:hover {
      color: #000000;
    }
  }

  .icon-angle-right {
    color: #707070;
    font-size: medium;

    &:hover {
      color: #000000;
    }
  }
}

.selected-card {
  border: 2px solid #0B2772;
  box-shadow: 0 0 9px #0b2772a6;
}
</style>
